import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MainModel } from '../../../model/MainModel';
import { concat, debounceTime, forkJoin, Observable, of, tap } from 'rxjs';
import App from '../../../model/App';
import Campaign from '../../../model/Campaign';
import { Router } from '@angular/router';
import PagingModel from '../../../model/PagingMoel';
import { AppConfig } from '../../../config/app.config';
import { APP_CONFIG } from '../../../config/config.token';

@Injectable({
  providedIn: 'root'
})
export class ServerClientService {

  constructor(
    private http:HttpClient, 
    private model:MainModel,
    private router: Router,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { 
  }
  client!:HttpClient;



  getDataTableData = (dataType: string, pagingModel: any, campaignId: number) => {
    var url = "";
    switch (dataType) {
      case 'donors':
        url = `${this.config.apiUrl}/api/donor/donor-columns?campaignId=${campaignId}`
        break;
      case 'donations':
        url = `${this.config.apiUrl}/api/donation/donation-columns`
        break
      case 'expenses':
        url = `${this.config.apiUrl}/api/expense/expense-columns`
        break
      default:
        break;
    }
    return this.http.get<any>(url, { withCredentials: true });
  }


  /**
   * When called updates the shared model campaigns list.
   */
  getLastAccessedCampaignData = ():Observable<any> => {
    return this.http.get<Array<Campaign>>(`${this.config.apiUrl}Campaign/recently-visited-campaigns`, { withCredentials: true } );
  }



  /**
   * Returning observable because there might be a bunch of things that cant be done until we have campaign data.
   */
  getAllCampaignsData = ():Observable<any> =>  {
    return this.http.get<Array<any>>(`${this.config.apiUrl}Campaign/user-campaigns`, { withCredentials: true });
  }

  


  /**
   * When called updates the shared model campaigns list.
   */
  getProductsAndFeaturesData = (campaignId:string|undefined = ""):Observable<any> => {
    if(campaignId === "")campaignId = this.model.selectedCampaign()?.campaignId;
    const x = this.http.get<any>(`${this.config.apiUrl}Campaign/user-campaign-products-features-pages?campaignId=${campaignId}`, { withCredentials: true } );
    return x;
  }



  
  /**
   * When called updates the shared model campaigns list.
   */
  saveLasAccessedCampaign = (campaign:Campaign):any => {
    const httpOptions:any = {
      headers: new HttpHeaders({
        'Accept': 'text/html, application/xhtml+xml, */*',
      }),
      responseType: 'text',
      withCredentials: true
    };

    return forkJoin(
      {
        lastAccessed: this.http.post<any>(`${this.config.apiUrl}Campaign/recently-visited-campaigns`, 
            { CampaignId:campaign.campaignId }, 
            httpOptions 
          ),
          productsAndFeatures: this.getProductsAndFeaturesData(campaign.campaignId)
      }
    );
  }




  
  /**
   * Get all campaigns a user has access to and the 
   * @returns
   */
  getUserCampaignSpecificNavigationData = ():Observable<any> => {
    const c:Campaign|null = this.model.selectedCampaign();
    if(c !== null){
      return this.getProductsAndFeaturesData(c.campaignId);
    }
    return new Observable();
  }





  // local browser window storage...
  getLocalSavedUserData = () => {
    const uData = window.localStorage.getItem("user-data");
    if(uData){
      return JSON.parse(uData);
    }else {
      return null;
    } 
  };
  



  
  setLocalSavedUserData = (value:object) => {
    let uData:any = window.localStorage.getItem("user-data");
    if(uData?.length){
      uData = JSON.parse(uData);
      Object.assign(uData, value);
      window.localStorage.setItem( "user-data", JSON.stringify(uData) );
      } else {
      window.localStorage.setItem( "user-data", JSON.stringify(value) );
      }
  }

}

