
/**
 * Populated by the UI and passed to methods that request data from back end services.
 */
export default class SecurityModel {
    features:Map<number, any> = new Map(); //here 
    pages:Map<number, any> = new Map(); //here 

    ingestAppsAndFeatures(_appsAndFeatures:Array<any>, type:String = "feature"){
        console.log(_appsAndFeatures);
        _appsAndFeatures.forEach(
            i => {
                if(type === "feature"){
                    this.features.set(i.featureId, i);
                }
                if(type === "page"){
                    this.pages.set(i.pageId, i);
                }
                if(i.features || i.pages){
                    this.ingestAppsAndFeatures(i.features || i.pages, (i.features ? "feature" : "page") );
                }
            }
        );
    }

    userHasAccessToFeature(requestedFeatureId:number):boolean{
        return requestedFeatureId === -1 || this.features.has(requestedFeatureId);
    }

    userHasAccessToPage(pageId:number):boolean{
        return pageId === -1 || this.pages.has(pageId);
    }

}