import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Table, TableModule, TablePageEvent } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';

import { ServerClientService } from '../../services/data/CampaignService/server-client.service';
import { MultiSelectModule } from 'primeng/multiselect';
import { Observable } from 'rxjs/internal/Observable';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CardModule } from 'primeng/card';
// import { PrimeIcons } from 'primeng/api';
import { cleanSVGForOutput } from '../../util/util';
import PagingModel from '../../model/PagingMoel';
import { SearchModel, DisplayColumn, LookupItem } from '../../model/SearchModel';
import { SelectItem } from 'primeng/api';
import { SearchService } from '../../services/data/SearchService/searchservice';

interface ExportColumn {
  title: string;
  dataKey: string;
}


/**
 * This component should take in a set of default colmns to show. 
 * These may be:
 * 1. Hard coded from the developer based on the page or user type
 * 2. injected from saved database preferences
 * 
 * Data Clumns:
 * Injected data fields should also match the columns passed in. 
 * 
 * 
 * Exported columns:
 * We may eventually want to control which fileds will be outut into omething like a print job - but for now we dont have a UI designed for this.
 */
@Component({
  selector: 'app-search',
  standalone: true,
  imports: [TableModule, CommonModule, ButtonModule, FormsModule, MultiSelectModule, ToggleButtonModule],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent implements OnInit {
  @ViewChild('dt')
  dt!: Table;
  dataObject!: Observable<unknown>;
  dataElements: Array<any> = [];
  isFullscreen: boolean = false;
  cols!: DisplayColumn[];
  exportColumns: ExportColumn[] = [];
  selectedStates: number[] = [];
  selectedFundCodes: number[] = [];
  defaultColumns: DisplayColumn[] = [];
  selectedColumns: DisplayColumn[] = [];
  defaultdropdowns: SelectItem[] = [];
  defaultTextDropdowns: SelectItem[] = [];
  states: LookupItem[] = [];
  fundCodes: LookupItem[] = [];
  rawData: any[] = [];

  // expandIcon:any = PrimeIcons.EXPAND;

  filters: Array<string> = [];

  //inputs...
  @Input({ required: true }) pagingModel: PagingModel = new PagingModel();
  @Input({ required: true }) columnData: SearchModel = { displayColumns: [], states: [], fundCodes: [], dropDownOptions: [], textOptions: [] };

  // outputs
  @Output() dataViewUpdate = new EventEmitter<PagingModel>();

  constructor(
    private servicesearch: SearchService,
  ) {

  }

  resetFilters() {
    this.dt.clear();
    this.selectedStates = [];
    this.selectedFundCodes = [];
  }
  updatePagingModel(start: number, maxResults: number = 10) {
    const pModel: PagingModel = this.pagingModel;
    pModel.maxResults = maxResults;
    pModel.startRow = start;
    // we emit and the service call should inject a new model...
    this.dataViewUpdate.emit(pModel);
  }

  pageChange(event: TablePageEvent) {
    // this.first = ;
    // this.rows = event.rows;
    console.debug("Search:", event);
    this.updatePagingModel(event.first, event.rows);
  }

  ngOnInit() {
    this.initializeColumns();
  }

  ngOnChanges() {
    this.initializeColumns();
  }

  private initializeColumns() {
    this.defaultColumns = this.columnData && this.columnData.displayColumns ? this.columnData.displayColumns : [];
    this.selectedColumns = this.columnData && this.columnData.displayColumns ? this.columnData.displayColumns.filter(x => x.isDefault) : [];
    this.defaultdropdowns = this.columnData && this.columnData.dropDownOptions ? this.convertLookupItemsToSelectItems(this.columnData.dropDownOptions) : [];
    this.defaultTextDropdowns = this.columnData && this.columnData.textOptions ? this.convertLookupItemsToSelectItems(this.columnData.textOptions) : [];
    this.states = this.columnData && this.columnData.states || [];
    this.fundCodes = this.columnData && this.columnData.fundCodes || [];
    this.cols = this.selectedColumns || [];
    this.exportColumns = this.cols.map((col: any) => ({ title: col.header, dataKey: col.field }));
  }

  private convertLookupItemsToSelectItems(lookupItems: LookupItem[]): SelectItem[] {
    return lookupItems.map((item: LookupItem) => ({ value: item.key, label: item.value }));
  }

  onGenerateReport() {
    // Collect the current filters from the p-table
    const filterData = this.buildFilterData(this.dt.filters);
    const campaignId = 215; // Replace with your campaignId
    const limit = 10000; // Number of items per page (adjust if needed)
    const offset = 0; // Page offset (adjust if needed)
    // Make the API call to generate the report
    this.loadFilteredData(campaignId, "", "", limit, offset, filterData);
  }

  exportData() {
    const dataToExport = this.dt.filteredValue ? this.dt.filteredValue : this.rawData;
    const csvData = this.convertToCSV(dataToExport);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const downloadLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.setAttribute('download', 'exported_data.csv');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  convertToCSV(data: any[]): string {
    if (!data || !data.length) return '';
  
    const headers = Object.keys(data[0]);
    const csvRows = [];
    csvRows.push(headers.join(','));
    for (const row of data) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '""');
        return `"${escaped}"`; 
      });
      csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
  }

  // Function to build filter data from the table's filters
  private buildFilterData(filters: { [key: string]: any }): string {
    // Convert filter object to a JSON string or format it to fit your API requirements
    if (this.selectedStates && this.selectedStates.length > 0) {
      filters['states'] = {
        values: this.selectedStates
      };
    }
    if (this.selectedFundCodes && this.selectedFundCodes.length > 0) {
      filters['fundcodes'] = {
        values: this.selectedFundCodes
      };
    }
    return JSON.stringify(filters);
  }
 
  multiSelectFilter(value: any, filter: any[]): boolean {
    return filter && filter.length ? filter.includes(value) : true;
  }
  textFilter(value: any, filter: any): boolean {
    return filter ? value.toString().toLowerCase().includes(filter.toLowerCase()) : true;
  }

  // Dropdown column filter function
  dropDownFilter(value: any, filter: any): boolean {
    return filter ? value === filter : true;
  }
  // Function to make API call with filters
  private loadFilteredData(campaignId: number, sort: string, order: string, limit: number, offset: number, filterData: string) {
    const params = {
      campaignId: campaignId,
      sort: sort,
      order: order,
      limit: limit.toString(),
      offset: offset.toString(),
      searchFilters: filterData
    };
    this.rawData = [];
    this.servicesearch.getRawData("donors", params).subscribe(
      (data: any[]) => {
        console.log(JSON.stringify(data));
        this.rawData = data;
      });
  }
}