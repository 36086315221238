import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    constructor(
        private http: HttpClient) {
    }
    client!: HttpClient;
    getRawData(dataType: string, params: { campaignId: number; sort: string; order: string; limit: string; offset: string; searchFilters: string; }) {
        var url = "";
        switch (dataType) {
            case 'donors':
                url = `/api/donor/donor-search`
                break;
            case 'donations':
                url = '/api/donation/donation-columns'
                break
            case 'expenses':
                url = '/api/expense/expense-columns'
                break
            default:
                break;
        }
        return this.http.get<any>(url, { params, withCredentials: true });
    }
}
