import { Component, OnInit } from '@angular/core';
import { SearchComponent } from '../../component/search/search.component';
import { filter, tap } from 'rxjs';
import { ServerClientService } from '../../services/data/CampaignService/server-client.service';
import { MainModel } from '../../model/MainModel';
import { JsonPipe } from '@angular/common';
import PagingModel from '../../model/PagingMoel';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchModel } from '../../model/SearchModel';

@Component({
  selector: 'app-search-page',
  standalone: true,
  imports: [SearchComponent, JsonPipe],
  templateUrl: './search.page.component.html',
  styleUrl: './search.component.scss'
})
export class SearchPageComponent {
  columnDataResult!: SearchModel;
  dataType: string | null = null;


  // ================================================================
  pagingModel: PagingModel = new PagingModel();
  // ================================================================

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private serverClient: ServerClientService,
    protected model: MainModel
  ) { }

  queryData(pagingModel: PagingModel) {
    console.debug("Search Page:", pagingModel);
  }

  ngOnInit() {
    this.dataType = this.router.url.split('vortex/')[1]
    console.log(this.dataType);
    this.serverClient.getDataTableData(this.dataType, this.pagingModel, 81).subscribe(
      (data: SearchModel) => {
        console.log(JSON.stringify(data));
        this.columnDataResult = data;
        // this.defaultColumns = data.displayColumns;
        // this.selectedColumns = data.displayColumns.filter(x => x.isDefault === true);
        // this.statesResult = data.states;
        // this.pagingModel.data = data;
        // this.pagingModel.count = data.length;
        //this.model.tableData = data.rows.slice(0,20);
      }
    );
  }
}
