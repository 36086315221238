
/**
 * Populated by the UI and passed to methods that request data from back end services.
 */
export default class PagingModel {
    data:Array<any> = []; //here 
    // -------------------------------- SORT
    sortField!:string;
    sortAsc:number = 0;
    // -------------------------------- PAGING
    maxResults:number = 5;
    startRow:number = 0;
    // -------------------------------- META
    count:number = 0
}