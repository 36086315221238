
import { Injectable } from "@angular/core";
import { cleanSVGForOutput } from "../util/util";
import { SearchPageComponent } from "../page/search/search.page.component";
// import { adminRoutes, apexRoutes, vortexFECRoutes, vortexJFCRoutes, vortexLiteRoutes, vortexRoutes } from "../app.routes";
import { expand } from "rxjs";
import { UserModel } from "./MainModel";
import { MenuItem } from "primeng/api";
import App from "./App";
//import { adminRoutes, apexRoutes, vortexFECRoutes, vortexJFCRoutes, vortexLiteRoutes } from "../app.routes";


export const Vortex_Navigation:Array<any> = [
    {
        label: 'Campaign Dashboard',
        icon: "fa-sharp fa-light fa-house-flag",
        featureId: -1,
        route: "vortex",  
    },
    {
        label: 'Donors',
        icon: "fa-sharp fa-light fa-users",
        items: [
        {
            label:"Search",
            pageId: -1,
            route:"vortex/donors"    
        },
        {
            label:"Add Donor",
            pageId: -1,
            route:"vortex/donors/add"
        },
        {
            label:"Bulk Update",
            pageId: -1,
            route:"vortex/donors/update"  
        },
        {
            label:"Manual Dedupes",
            pageId: -1,
            route:"vortex/donors/dedupe"
        },
        {
            label:"Flags",
            pageId: -1,
            route:"vortex/donors/flags"
        },
        {
            label:"Call Sheets",
            pageId: -1,
            route:"vortex/donors/call-sheets"
        },
        ]
    },
    {
        label: 'Donations',
        icon: "fa-sharp fa-light fa-money-check-dollar-pen",
        items: [
            {
            label:"Search",
            pageId: -1,
            route:"vortex/donations"
            },
            {
            label:"Add Donation",
            pageId: -1,
            route:"vortex/donations/add"
            },
            {
            label:"Bulk Upload",
            pageId: -1,
            route:"vortex/donations/upload"
            },
            {
            label:"Batches",
            pageId: -1,
            route:"vortex/donations/batches"
            },
            {
            label:"Flags",
            pageId: -1,
            route:"vortex/donations/flags"
            },
            {
            label:"Compliance Letters",
            pageId: -1,
            route:"vortex/donations/compliance-letters"
            },
        ]
    },
    {
        label: 'Fundraising Activity',
        icon: "fa-sharp fa-light fa-calendar-days",
        featureId: 10,
        items: [
        {
            label:"Fundraisers",
            pageId: 29,
            route:"vortex/fundraisers"
        },
        {
            label:"Events",
            pageId: -1,
            route:"vortex/fundraisers/events"
        },
        {
            label:"Initiatives",
            pageId: -1,
            route:"vortex/fundraisers/initiatives"
        }
        ]
    },
    {
        label: 'Expenses',
        icon: "fa-sharp fa-light fa-chart-pie-simple-circle-dollar",
        featureId: 12,
        pageId: 35,
        route:"vortex/expenses"
    },
    {
        label: 'Reporting Center',
        route: "vortex/reporting-center",
        featureId: 14,
        pageId: 38,
        icon: "fa-sharp fa-light fa-chart-pie-simple-circle-dollar"
    },
    {
        label: 'FEC',
        icon: "fa-sharp fa-light fa-landmark",
        featureId: 14,
        pageId: 38,
        items: [
            {
            label:"Vortex Flier",
            pageId: 39,
            route:"vortex/fec/vortex"
            },
            {
            label:"Settiings",
            pageId: 39,
            route:"vortex/fec/settings"
            },
            {
            label:"Report Cleanup",
            pageId: 39,
            route:"vortex/fec/report-cleanup"
            }
        ]
    },
    {
        label: 'JFC',
        icon: "fa-sharp fa-light fa-flag-checkered",
        featureId: 16,
        items: [
            {
            label:"Allocations",
            pageId: 40,
            route:"vortex/jfc/allocations"
            },
            {
            label:"Distribution Batches",
            pageId: 40,
            route:"vortex/jfc/batches"
            }
        ]
    }
];
    
export const Admin_Tools: Array<any> = [
        {
            label:"Campaign Settings",
            icon: "fa-sharp fa-light fa-megaphone",
            route:"/admin/campaign-settings/campaigns",
            items: [
                {
                label:"Campaign List",
                featureId: -1,
                route:"/admin/campaign-settings"
                },
                {
                label:"Fund Codes",
                featureId: -1,
                route:"/admin/campaign-settings/fund-codes"
                },
                {
                label:"Donation Source Rates",
                featureId: -1,
                route:"/admin/campaign-settings/donation-source-rates"
                },
                {
                label:"Accounting System",
                featureId: -1,
                route:"/admin/campaign-settings/accounting-system"
                },
                {
                label:"Compliance",
                featureId: -1,
                route:"/admin/campaign-settings/compliance"
                },
                {
                label:"Search Throttle",
                featureId: -1,
                route:"/admin/campaign-settings/search"
                },
                {
                label:"Donor Campaign Limits",
                featureId: -1,
                route:"/admin/campaign-settings/donor-limits"
                },
                {
                label:"Campaign Types",
                featureId: -1,
                route:"/admin/campaign-settings/campaign-types"
                },
                {
                label:"Default Approver",
                featureId: -1,
                route:"/admin/campaign-settings/approver"
                },
                {
                label:"Power BI",
                featureId: -1,
                route:"/admin/campaign-settings/reporting"
                }
            ]
        },
        {
            label:"Organization Settings",
            icon: "fa-sharp fa-light fa-landmark",
            featureId: -1,
            route:"/donors/dedupes"
        },
        {
            label:"Users",
            icon: "fa-sharp fa-light fa-users-gear",
            items: [
            {
                label:"Invite New User",
                featureId: -1,
                route:"/admin/users/invite-new-user"
            },
            {
                label:"All Users",
                featureId: -1,
                route:"/admin/users/all-users",
            },
            {
                label:"User Bulk Upload",
                featureId: -1,
                route:"/admin/users/user-bulk-upload"
            }
            ]
        },
        {
            label:"System Errors",
            icon: "fa-sharp fa-light fa-message-exclamation",
            items: [
            {
                label:"Login errors",
                featureId: -1,
                route:"/admin/system-errors/login-errors",
            },
            {
                label:"Logout errors",
                featureId: -1,
                route:"/admin/system-errors/logout-errors",
            },
            {
                label:"Transaction Errors",
                featureId: -1,
                route:"/admin/system-errors/transaction-errors"
            }
            ]
        },
    ];

export const Anonymous_Navigation:Array<any> = [
    {   
        label: 'Select Campaign',
        icon: "fa-sharp fa-light fa-thumbtack",
        featureId: -1,
        pageId: -1,
        route: "campaign-selection",    
    },
];

export const APEX: Array<any>= [
    {
        label: 'APEX',
        expanded: true,
        featureId: 3,
        icon: "fa-sharp fa-light fa-flag-checkered",
        items: [
        {
            label:"My Widgets",
            pageId: 41,
            route:"/apex/donors/dedupes",
            
        },
        {
            label:"Settings",
            pageId: 42,
            route:"/apex/donors/flags"
        }
        ]
    },
];

export const Vortex_Lite: Array<any> = [
    {
    label:"My Fundraising Tally",
    pageId: 43,
    route:"vortex-lite/fundraising-tally",
    },
    {
    label:"Events",
    pageId: 26,
    route:"vortex-lite/events",
    },
    {
    label:"Fundraisers",
    pageId: 29,
    route:"vortex-lite/fundraisers",
    },
    {
    label:"Donor Flags",
    pageId: 15,
    route:"vortex-lite/donor-flags",
    },
    {
    label:"Club Settings",
    pageId: 44,
    route:"vortex-lite/club-settings",
    }   
]   

export const NavSetsByAppName:Map<string, Array<any>> = new Map();
NavSetsByAppName.set("Vortex3", Vortex_Navigation);
NavSetsByAppName.set("VortexLite", Vortex_Lite);
NavSetsByAppName.set("Apex", APEX);
NavSetsByAppName.set("Admin Tools", Admin_Tools);


export const NavOrder = [
    {
        label:"vortex3",
        page:"campaign-dashboard"
    },
    {
        label:"vortexlite",
        page:"campaign-dashboard"
    },
    {
        label:"apex",
        page:"campaign-dashboard"
    },
    {
        label:"admin tools", // admins have access to everything...?
        page:"campaign-dashboard"
    }
]


// NOW INCLUDED IN THE MAIN MODEL...AND THAT IS INJECTED...
export default class NavModel {

    applicationNavset:Array<any> = [];
    isShowBreadcrumb:boolean = !false;

}