
<div class="card">

    <div id="search-column-toggle" class="flex vertical">
        <p-multiSelect 
            [options]="defaultColumns" 
            [(ngModel)]="selectedColumns"
            class="multi-select-columns"
            [virtualScrollItemSize]="43"
            optionLabel="displayColumn" 
            display="chip" 
            selectedItemsLabel="{0} columns selected"
            placeholder="Choose Columns" 
            [maxSelectedLabels]="100" 
            id="multi-select-columns" />
    </div>

    <!-- {{selectedColumns | json}} -->

    <div class="search-actions grid-nogutter py-3">
        <div class="button-group col-6">
            <!-- 
            <p-toggleButton 
                onIcon="pi pi-times" 
                offIcon="pi pi-expand"  
                (click)="dt.exportCSV()" 
                /> -->
            <button pButton pRipple (click)="resetFilters()" class="p-button-tertiary" id="button-clear-filters">
                <i class="pi pi-filter" style="font-size: 1rem"></i>
                Clear
            </button>
        </div>
        <div class="button-group col-6 text-right">
            <button pButton pRipple label="Generate Report" (click)="onGenerateReport()" class="p-button-secondary" id="button-generate-report">
            </button>
            <button pButton pRipple label="Export" (click)="exportData()" class="" id="button-export">
            </button>
        </div>
    </div>

    <p-table 
        #dt 
        [scrollable]="true" 
        selectionMode="multiple" 
        [exportHeader]="'customExportHeader'"
        [reorderableColumns]="true" 
        [paginator]="true" 
        scrollHeight="flex" 
        [value]="rawData||[]"
        [columns]="selectedColumns" 
        [globalFilterFields]="filters"
        [rows]="pagingModel.maxResults"
        [sortField]="pagingModel.sortField" 
        [sortOrder]="pagingModel.sortAsc" 
        [rowsPerPageOptions]="[5, 10, 20, 100]"
        (onPage)="pageChange($event)" id="table-data">

        <ng-template pTemplate="caption">
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width:3rem">
                </th>
                <th style="width:3rem">
                </th>
                <th *ngFor="let col of columns" 
                    pReorderableColumn 
                    pFrozenColumn 
                    nowrap
                    pSortableColumn={{col.columnName}} 
                    id="column-{{col.columnName}}">

                    <div class="sortable-title">
                        <div class="column-title">{{col.displayColumn}}</div>
                        <p-sortIcon field={{col.columnName}} id="sort-icon-{{col.columnName}}" />
                    </div>
                </th>
            </tr>
            <tr>
                <th style="width:6rem"></th>
                <th style="width:6rem">
                    <input type="checkbox" id="checkbox-select-all" />
                </th>
                <th *ngFor="let col of columns">
                    <div *ngIf="col.displayColumnType && col.displayColumnType.columnType === 'StatesMultiSelect'">
                        <p-columnFilter field="representative" matchMode="in" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect [options]="states" optionLabel="value" optionValue="key" [(ngModel)]="selectedStates" (onChange)="dt.filter(selectedStates, col.columnName, 'custom')" placeholder="Select States" [maxSelectedLabels]="1"  appendTo="body" [displaySelectedLabel]="true" emptyMessage = "No states found" [selectedItemsLabel]="'{0} items selected'"></p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                    <div *ngIf="col.displayColumnType && col.displayColumnType.columnType === 'FundCodesMultiSelect'">
                        <p-multiSelect [options]="fundCodes" optionLabel="value" optionValue="key" [(ngModel)]="selectedFundCodes" placeholder="Select FundCodes" [maxSelectedLabels]="1"  appendTo="body" [displaySelectedLabel]="true" emptyMessage = "No FundCodes found"  [selectedItemsLabel]="'{0} items selected'"></p-multiSelect>
                    </div>
                    <div *ngIf="col.displayColumnType && (col.displayColumnType.columnType === 'Text')">
                        <p-columnFilter type="text" field={{col.columnName}} placeholder="All" ariaLabel="Filter Name" [matchModeOptions]="defaultTextDropdowns" filterFunction="textFilter.bind(this)" id="filter-{{col.columnName}}" [matchMode]="''"/>
                    </div>
                    <div *ngIf="col.displayColumnType && (col.displayColumnType.columnType === 'DropDown')">
                        <p-columnFilter type="text" field={{col.columnName}} placeholder="All" ariaLabel="Filter Name" [matchModeOptions]="defaultdropdowns" id="filter-{{col.columnName}}" [matchMode]="''"/>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
            <tr [pReorderableRow]="index" id="row-{{index}}">
                <td>
                    <span class="pi pi-bars" pReorderableRowHandle></span>
                </td>
                <td>
                    <input type="checkbox" id="checkbox-row-{{index}}" />
                </td>
                <td *ngFor="let col of columns" nowrap>
                    {{rowData[col.columnName]}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!-- element below should be conditional - only appears when table is empty, before user interacts with the filters -->
    <!-- <p>Adjust filters above and press Generate Report button to view your report.</p> -->
</div>

<br />
<br />
<br />
<br />
<ul>
    <li>Start: {{pagingModel.startRow}}</li>
    <li>Visible Rows: {{pagingModel.maxResults}}</li>
    <li>Number of Rows: {{pagingModel.count}}</li>
</ul>